@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Regular'), local('FivoSansModern-Regular'), url('/fonts/FivoSansModern-Regular.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Medium'), local('FivoSansModern-Medium'), url('/fonts/FivoSansModern-Medium.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Bold'), local('FivoSansModern-Bold'), url('/fonts/FivoSansModern-Bold.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Extra Black'), local('FivoSansModern-ExtraBlack'), url('/fonts/FivoSansModern-ExtraBlack.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Heavy'), local('FivoSansModern-Heavy'), url('/fonts/FivoSansModern-Heavy.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Oblique'), local('FivoSansModern-Oblique'), url('/fonts/FivoSansModern-Oblique.woff2') format('woff2');
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Medium Oblique'), local('FivoSansModern-MediumOblique'), url('/fonts/FivoSansModern-MediumOblique.woff2') format('woff2');
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Bold Oblique'), local('FivoSansModern-BoldOblique'), url('/fonts/FivoSansModern-BoldOblique.woff2') format('woff2');
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern ExtBlk Obliq'), local('FivoSansModern-ExtBlkObliq'), url('/fonts/FivoSansModern-ExtBlkObliq.woff2') format('woff2');
  font-weight: 800;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: 'Fivo Sans Modern';
  src: local('Fivo Sans Modern Heavy Oblique'), local('FivoSansModern-HeavyOblique'), url('/fonts/FivoSansModern-HeavyOblique.woff2') format('woff2');
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}
