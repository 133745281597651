/* If you need to add @import statements, do so up here */
@import "fivo-font.css";
@import "jit-refresh.css"; /* triggers frontend rebuilds */

/* Set up Tailwind imports */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .bg-header {
        background-image: url(/images/ui/arrow-pattern.png), url(/images/ui/arrows.svg);
        background-position: 50% 40px, 52% 40px;
        background-repeat: repeat, no-repeat;
    }
}

@layer components {
    .hiw-card {
        @apply flex max-w-xl items-start gap-4 border border-gray-100 p-8;
    }

    .hiw-card:hover {
        @apply cursor-pointer rounded shadow-md;
    }

    .hiw-card__active {
        @apply rounded bg-white shadow-md;
    }

    .hiw-card__active:hover {
        @apply cursor-default;
    }

    .hiw-card-image,
    .hiw-card-extra,
    .hiw-icon,
    .hiw-card__active .hiw-plus {
        @apply hidden;
    }

    .hiw-card-image__active,
    .hiw-card__active .hiw-card-extra,
    .hiw-card__active .hiw-icon {
        @apply block;
    }
}

[data-session-checker-target="user"] {
    display: none;
}
